<template>
<div class="container-fluid mt-5 pt-5">
    <BasePageBreadCrumb :pages="pages" title="novo tipo" :menu="menu"/>

    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="card card-bg-trans">
                <div class="card-body pt-4 px-0">
                    <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                        <div class="form-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Nome de identificação (uso interno)</label>
                                        <input required type="text" v-model="personalization_type.identification_name" class="form-control" :class="{'is-invalid': errors.has('name')}" name="name" v-validate="'required'">
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <label>Nome do tipo</label>
                                        <input type="text" id="inputSuccess1" class="form-control" v-model="personalization_type.name" name="name" v-validate="'required'" :class="{'is-invalid': errors.has('name')}">
                                    </div>
                                </div>
                                <div class="col-12 pt-3"></div>
                                <div class="row">
                                    <div class="col-12 col-md-6 pb-2">
                                        <label>Imagem Capa</label>
                                        <vue-upload-multiple-image
                                            @upload-success="uploadImageSuccess"
                                            @before-remove="beforeRemove"
                                            @edit-image="editImage"
                                            :data-images="personalization_type.image"
                                            idUpload="myIdUpload"
                                            editUpload="myIdEdit"
                                            dragText="Clique ou arraste uma image aqui"
                                            browseText="Procurar imagem"
                                            :multiple="false"
                                            :showPrimary="false"
                                            :showEdit="false">
                                        </vue-upload-multiple-image>
                                    </div>
                                    <div class="col-12 col-md-6 pb-2">
                                        <label>Imagem Capa (Marcação) </label>
                                        <vue-upload-multiple-image
                                            @upload-success="uploadImageSuccess2"
                                            @before-remove="beforeRemove2"
                                            @edit-image="editImage2"
                                            :data-images="personalization_type.image2"
                                            idUpload="myIdUpload2"
                                            editUpload="myIdEdit2"
                                            dragText="Clique ou arraste uma image aqui"
                                            browseText="Procurar imagem"
                                            :multiple="false"
                                            :showPrimary="false"
                                            :showEdit="false">
                                        </vue-upload-multiple-image>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-actions">
                            <div class="text-right">
                                <button type="reset" class="btn btn-dark">Cancelar</button>
                                <button type="submit" class="btn btn-info ml-2">{{ id ? 'Salvar' : 'Cadastrar' }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import KitConfigService from "@/services/resources/KitConfigService";
import VueUploadMultipleImage from "vue-upload-multiple-image";

const service = KitConfigService.build();

export default {
  components:{
       VueUploadMultipleImage
  },
  data() {
    return {
      personalization_type: {
          name: null,
          image: [],
          image2: [],
          identification_name: "",
          type: 'PERSONALIZATION_TYPE'
      },
      search: '',
      id: null,
      pages:[
        {
            name: 'dashboard',
            to: 'Dashboard'
        }
      ],
      menu:[
        {
            name: 'Dashboard',
            to: 'Dashboard'
        }
      ]
    };
  },
  methods: {
    uploadImageSuccess(formData, index, fileList) {
        this.personalization_type.image = fileList;
    },
    beforeRemove(index, done, fileList) {
        var r = confirm("Remover imagem?");
        if (r == true) {
            this.personalization_type.image.splice(index, 1);
            done();
        }
    },
    editImage(formData, index, fileList) {
        this.personalization_type.image = fileList;
    },
    uploadImageSuccess2(formData, index, fileList) {
        this.personalization_type.image2 = fileList;
    },
    beforeRemove2(index, done, fileList) {
        var r = confirm("Remover imagem?");
        if (r == true) {
            this.personalization_type.image2.splice(index, 1);
            done();
        }
    },
    editImage2(formData, index, fileList) {
        this.personalization_type.image2 = fileList;
    },
    save(){
      this.$validator.validateAll().then((result) => {
        if (result) {

            let personalization_type = Object.assign({}, this.personalization_type)
            personalization_type.image = personalization_type.image[0];
            personalization_type.image2 = personalization_type.image2[0];

            if(this.id){
                personalization_type.id = this.id;
                personalization_type.url = 'single';

                service
                .update(personalization_type)
                .then(resp => {
                    this.$bvToast.toast('Seu tipo foi atualizado com sucesso!', {
                        title: 'Tipo atualizado',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
                })
                .catch(err => {
                })
            }else{
                service
                .create(personalization_type)
                .then(resp => {
                    this.$bvToast.toast('Seu tipo foi criado com sucesso!', {
                        title: 'Tipo criado',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
                })
                .catch(err => {
                })
            }
          }
      });
    },
    fetchPersonalizationType(){

        if(!this.id){
            return;
        }

        let data = {
            id: this.id
        }

        service
          .read(data)
            .then(resp => {
              this.personalization_type.name = resp.name;
               this.personalization_type.identification_name = resp.identification_name;

              this.personalization_type.image = [{path: resp.image}];
              this.personalization_type.image2 = [{path: resp.image2}];
            })
            .catch(err => {
              console.log(err)
            })
    }

  },
  mounted() {
      this.id = this.$route.params.id;
      this.fetchPersonalizationType();
  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>